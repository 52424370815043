import { subscriptionService } from "src/services/api";

const state = {
  data: [],
  metaData: {},
  status: {
    error: false,
    message: "",
  },
  errors: [],
  tableFilter: {
    SearchTerm: null,
    method: null,
    status: null,
    dateStart: null,
    dateEnd: null,
  },
  loadingTable: false,
};

const defaultFilter = {
  SearchTerm: null,
  method: null,
  status: null,
  dateStart: null,
  dateEnd: null,
};

const actions = {
  async applyTableFilter({ commit, dispatch }, params) {
    commit("setTableFilter", {
      ...defaultFilter,
      ...params,
    });
    dispatch("getAll", params);
  },

  async clearTableFilter({ commit }) {
    commit("setTableFilter", {
      ...defaultFilter,
    });
  },

  async oneTimePayment({ commit, dispatch }, payload) {
    await subscriptionService.oneTimePayment(payload).then(
      () => {
        commit("statusOk");
        dispatch("snackbar/success", `Payment "${payload.amount}" successful`, {
          root: true,
        });
      },
      (error) => {
        dispatch("alert/error", error, { root: true });
        commit("statusFail", error.responseText);
        dispatch("snackbar/error", `Payment "${payload.amount}" failed`, {
          root: true,
        });
      }
    );
  },
  async cancelSubscription({ commit, dispatch }, payload) {
    await subscriptionService.cancelSubscription(payload).then(
      () => {
        commit("statusOk");
        dispatch("snackbar/success", `Cancel subscription successful`, {
          root: true,
        });
      },
      (error) => {
        dispatch("alert/error", error, { root: true });
        commit("statusFail", error.responseText);
        dispatch("snackbar/error", `Cancel subscription failed`, {
          root: true,
        });
      }
    );
  },

  async activateSubscription({ commit, dispatch }, params) {
    await subscriptionService.activateSubscription(params).then(
      () => {
        commit("statusOk");
        dispatch("snackbar/success", `Activate subscription successful`, {
          root: true,
        });
      },
      (error) => {
        dispatch("alert/error", error, { root: true });
        commit("statusFail", error.responseText);
        dispatch("snackbar/error", `Activate subscription failed`, {
          root: true,
        });
      }
    );
  },

  async getStatus({ commit, dispatch }, payload) {
    await subscriptionService.getStatus(payload).then(
      (response) => {
        dispatch("account/updateSubscriptionStatus", response.message, {
          root: true,
        });
      },
      (error) => {
        dispatch("alert/error", error, { root: true });
        commit("statusFail", error.responseText);
        dispatch("snackbar/error", `Get status failed`, {
          root: true,
        });
      }
    );
  },

  async getAll({ commit, dispatch }, payload) {
    commit("setLoadingTable", true);
    await subscriptionService.getAll(payload).then(
      (response) => {
        commit("setData", response.data);
        commit("setMetaData", response.metaData);
        commit("setLoadingTable", false);
      },
      (error) => {
        commit("setLoadingTable", false);
        dispatch("alert/error", error, { root: true });
        commit("statusFail", error.responseText);
        dispatch("snackbar/error", `Get status failed`, {
          root: true,
        });
      }
    );
  },
};

const mutations = {
  setLoadingTable(state, data) {
    state.loadingTable = data;
  },

  setTableFilter(state, data) {
    state.tableFilter = data;
  },

  statusOk(state) {
    state.status = { error: false, message: "ok" };
  },
  statusFail(state, message) {
    state.status = { error: true, message: message };
  },
  setData(state, data) {
    state.data = data;
  },
  setMetaData(state, data) {
    state.metaData = data;
  },
};

const getters = {
  getMetaData(state) {
    return state.metaData;
  },
};

export const subscription = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
