import { customRequest, handleResponse } from "src/helpers";
import { buildQueryString } from "src/utils/helpers";

const config = {
  apiUrl: process.env.VUE_APP_BACKEND_URL,
};

export const authService = {
  requestNewAccountVerificationCode,
  verifyAccount,
};

async function requestNewAccountVerificationCode(params) {
  const requestOptions = {
    ...customRequest("POST"),
  };
  const queryString = buildQueryString(params);
  return fetch(`${config.apiUrl}/Authenticate/requestNewAccountVerificationCode?${queryString}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

async function verifyAccount(params) {
  const requestOptions = {
    ...customRequest("POST"),
  };
  const queryString = buildQueryString(params);
  return fetch(`${config.apiUrl}/Authenticate/verifyAccount?${queryString}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}