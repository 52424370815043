import { authService } from "src/services/api";
import { router } from "../../src/router";

const user = JSON.parse(localStorage.getItem('user'));
const initialState = user
  ? { status: { loggedIn: true, error: false, message: '' }, user }
  : { status: { loggedIn: false, error: false, message: '' }, user: null };

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    login({ commit }, user) {
      return authService.login(user).then(
        user => {
          commit('loginSuccess', user);
          return Promise.resolve(user);
        },
        error => {
          commit('loginFailure');
          return Promise.reject(error);
        }
      );
    },
    logout({ commit }) {
      authService.logout();
      commit('logout');
    },
    register({ commit }, user) {
      return authService.register(user).then(
        response => {
          commit('registerSuccess');
          return Promise.resolve(response.data);
        },
        error => {
          commit('registerFailure');
          return Promise.reject(error);
        }
      );
    },
    async requestNewAccountVerificationCode({ commit, dispatch }, params) {
      await authService.requestNewAccountVerificationCode(params).then(
        () => {
          commit("statusOk");
          dispatch("snackbar/success", `Verification Code email sent`, {
            root: true,
          });
        },
        (error) => {
          commit("statusError", error);
          dispatch(
            "snackbar/error",
            `Error emailing verification code`,
            {
              root: true,
            }
          );
        }
      );
    },
    async verifyAccount({ commit, dispatch }, params) {
      await authService.verifyAccount(params).then(
        () => {
          commit("statusOk");
          dispatch("snackbar/success", `Email Verified`, {
            root: true,
          });
          // Update emailVerified flag in localStorage
          const localUser = JSON.parse(localStorage.getItem("user"));
          localUser.emailVerified = true;
          localStorage.setItem("user", JSON.stringify(localUser));
          router.push("/dashboard");
        },
        (error) => {
          commit("statusError", error);
          dispatch(
            "snackbar/error",
            `Error verifying email`,
            {
              root: true,
            }
          );
        }
      );
    },
  },
  mutations: {
    loginSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    registerSuccess(state) {
      state.status.loggedIn = false;
    },
    registerFailure(state) {
      state.status.loggedIn = false;
    },
    statusOk(state) {
      state.status = { error: false };
    },
    statusError(state, message) {
      state.status = { error: true, message: message };
    }
  }
};