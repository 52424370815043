// import { userService } from "api-client";
import { userService } from "src/services/api";
import { router } from "../../src/router";
import UTIL from "src/helpers/utility";

const user = JSON.parse(localStorage.getItem("user"));

const state = {
  status: {},
  errors: [],
  statusText: "",
  data: user,
  account: {},
  taxRate: 0,
  states: [
    { name: "Alabama ", code: "AL", rate: 4.0 },
    { name: "Alaska ", code: "AK", rate: 0.0 },
    { name: "Arizona ", code: "AZ", rate: 5.6 },
    { name: "Arkansas ", code: "AR", rate: 6.5 },
    { name: "California ", code: "CA", rate: 7.25 },
    { name: "Colorado ", code: "CO", rate: 2.9 },
    { name: "Connecticut ", code: "CT", rate: 6.35 },
    { name: "Delaware ", code: "DE", rate: 0.0 },
    { name: "Florida ", code: "FL", rate: 6.0 },
    { name: "Georgia ", code: "GA", rate: 4.0 },
    { name: "Hawaii ", code: "HI", rate: 4.0 },
    { name: "Idaho ", code: "ID", rate: 6.0 },
    { name: "Illinois ", code: "IL", rate: 6.25 },
    { name: "Indiana ", code: "IN", rate: 7.0 },
    { name: "Iowa ", code: "IA", rate: 6.0 },
    { name: "Kansas ", code: "KS", rate: 6.5 },
    { name: "Kentucky ", code: "KY", rate: 6.0 },
    { name: "Louisiana ", code: "LA", rate: 4.45 },
    { name: "Maine ", code: "ME", rate: 5.5 },
    { name: "Maryland ", code: "MD", rate: 6.0 },
    { name: "Massachusetts ", code: "MA", rate: 6.25 },
    { name: "Michigan ", code: "MI", rate: 6.0 },
    { name: "Minnesota ", code: "MN", rate: 6.875 },
    { name: "Mississippi ", code: "MS", rate: 7.0 },
    { name: "Missouri ", code: "MO", rate: 4.225 },
    { name: "Montana ", code: "MT", rate: 0.0 },
    { name: "Nebraska ", code: "NE", rate: 5.5 },
    { name: "Nevada ", code: "NV", rate: 6.85 },
    { name: "New Hampshire", code: "NH", rate: 0 },
    { name: "New Jersey", code: "NJ", rate: 6 },
    { name: "New Mexico", code: "NM", rate: 5 },
    { name: "New York", code: "NY", rate: 4 },
    { name: "North Carolina", code: "NC", rate: 4 },
    { name: "North Dakota", code: "ND", rate: 5 },
    { name: "Ohio ", code: "OH", rate: 5.75 },
    { name: "Oklahoma ", code: "OK", rate: 4.5 },
    { name: "Oregon ", code: "OR", rate: 0.0 },
    { name: "Pennsylvania ", code: "PA", rate: 6.0 },
    { name: "Rhode Island", code: "RI", rate: 7 },
    { name: "South Carolina", code: "SC", rate: 6 },
    { name: "South Dakota", code: "SD", rate: 4 },
    { name: "Tennessee ", code: "TN", rate: 7.0 },
    { name: "Texas ", code: "TX", rate: 6.25 },
    { name: "Utah ", code: "UT", rate: 4.85 },
    { name: "Vermont ", code: "VT", rate: 6.0 },
    { name: "Virginia ", code: "VA", rate: 4.3 },
    { name: "Washington ", code: "WA", rate: 6.5 },
    { name: "Washington DC ", code: "DC", rate: 6.0 },
    { name: "West Virginia", code: "WV", rate: 6 },
    { name: "Wisconsin ", code: "WI", rate: 5.0 },
    { name: "Wyoming ", code: "WY", rate: 4.0 },
  ],
};

const actions = {
  async login({ dispatch, commit }, credentials) {
    await userService.login(credentials.username, credentials.password).then(
      (user) => {
        dispatch("snackbar/success", "Login successful", { root: true });
        commit("loginSuccess", user);
        router.push(user?.subscriptionStatus?.toLocaleLowerCase() !== 'active'
          ? "/account/billing"
          : "/"
        );
      },
      (error) => {
        commit("loginFailure", error);
        dispatch("alert/error", error, { root: true });
      }
    );
  },

  logout({ dispatch, commit }) {
    userService.logout();
    dispatch("snackbar/success", "Logout successful", { root: true });
    commit("logout");
  },

  async register({ dispatch, commit }, user) {
    commit("registerRequest", user);
    await userService.register(user).then(
      (user) => {
        commit("registerSuccess", user);
        router.push("/verify");
        setTimeout(() => {
          dispatch(
            "alert/success",
            { message: "Registration successful" },
            { root: true }
          );
        });
      },
      (response) => {
        commit("registerFailure", response);
        dispatch("alert/error", response, { root: true });
      }
    );
  },

  async create({ dispatch, commit }, payload) {
    commit("createRequest", user);
    await userService.register(payload).then(
      () => {
        // commit("createSuccess", user);
        dispatch(
          "snackbar/success",
          `Add user "${payload.userName}" successful`,
          { root: true }
        );
      },
      (error) => {
        commit("createFailure", error);
        dispatch("alert/error", error, { root: true });
      }
    );
  },

  async requestPassword({ dispatch, commit }, payload) {
    commit("createRequest", user);
    await userService.requestPassword(payload).then(
      () => {
        dispatch(
          "snackbar/success",
          `Request reset password "${payload}" successful`,
          { root: true }
        );
      },
      (error) => {
        commit("createFailure", error);
        dispatch("alert/error", error, { root: true });
      }
    );
  },

  async update({ dispatch, commit }, payload) {
    await userService.update(payload).then(
      () => {
        commit("statusOk");
        dispatch(
          "snackbar/success",
          `Update user "${payload.userName}" successful`,
          { root: true }
        );
      },
      (error) => {
        commit("createFailure", error);
        dispatch("alert/error", error, { root: true });
      }
    );
  },

  async delete({ dispatch, commit }, payload) {
    await userService.delete(payload).then(
      () => {
        commit("statusOk");
        dispatch(
          "snackbar/success",
          `Delete user "${payload.userName}" successful`,
          { root: true }
        );
      },
      (error) => {
        commit("createFailure", error);
        dispatch("alert/error", error.message, { root: true });
      }
    );
  },

  async deactivate({ dispatch, commit }, payload) {
    await userService.delete(payload).then(
      () => {
        commit("statusOk");
        dispatch(
          "snackbar/success",
          `Deactivate user "${payload.userName}" successful`,
          { root: true }
        );
      },
      (error) => {
        commit("createFailure", error);
        dispatch("alert/error", error.message, { root: true });
      }
    );
  },

  async reactivate({ dispatch, commit }, payload) {
    await userService.reactivate(payload).then(
      () => {
        commit("statusOk");
        dispatch(
          "snackbar/success",
          `Reactivate user "${payload.userName}" successful`,
          { root: true }
        );
      },
      (error) => {
        commit("createFailure", error);
        dispatch("alert/error", error.message, { root: true });
      }
    );
  },

  async changePassword({ commit, dispatch }, payload) {
    await userService.changePassword(payload, true).then(
      () => {
        commit("statusOk");
        dispatch("snackbar/success", `Password changed`, { root: true });
      },
      (error) => {
        commit("statusError", error);
      }
    );
  },

  async changeUserPassword({ commit, dispatch }, payload) {
    await userService.changePassword(payload, false).then(
      () => {
        commit("statusOk");
        dispatch("snackbar/success", `Password changed`, { root: true });
      },
      (error) => {
        commit("statusError", error);
      }
    );
  },

  async changeAdminPassword({ commit, dispatch }, payload) {
    await userService.changeAdminPassword(payload).then(
      () => {
        commit("statusOk");
        dispatch("snackbar/success", `Password changed`, { root: true });
      },
      (error) => {
        commit("statusError", error);
      }
    );
  },

  async getTaxRate({ commit, dispatch }) {
    await userService.getTaxRate().then(
      (response) => {
        commit("setTaxRate", response.data);
      },
      (error) => {
        commit("statusError", error.message);
        dispatch("alert/error", error, { root: true });
      }
    );
  },

  async requestEmailVerification({ commit, dispatch }) {
    await userService.requestEmailVerification().then(
      () => {
        commit("statusOk");
        dispatch("snackbar/success", `Request email verification sent`, {
          root: true,
        });
      },
      () => {
        commit("statusError");
        dispatch(
          "snackbar/error",
          `Sending verification's email fail, Please check your email address.`,
          {
            root: true,
          }
        );
      }
    );
  },

  async verifyEmail({ commit, dispatch }, payload) {
    await userService.verifyEmail(payload).then(
      () => {
        commit("statusOk");
        dispatch("snackbar/success", `Email confirmed`, { root: true });
      },
      (error) => {
        commit("statusError");
        dispatch("snackbar/error", error, {
          root: true,
        });
      }
    );
  },

  async requestPhoneVerification({ commit, dispatch }) {
    await userService.requestPhoneVerification().then(
      () => {
        commit("statusOk");
        dispatch("snackbar/success", `Request phone number verification sent`, {
          root: true,
        });
      },
      () => {
        commit("statusError");
        dispatch(
          "snackbar/error",
          `Sending verification's code fail, Please check your phone number.`,
          {
            root: true,
          }
        );
      }
    );
  },

  async verifyPhone({ dispatch, commit }, payload) {
    await userService.verifyPhone(payload).then(
      () => {
        commit("statusOk");
        dispatch("snackbar/success", `Phone confirmed`, { root: true });
      },
      (error) => {
        commit("statusError");
        dispatch("snackbar/error", error, {
          root: true,
        });
      }
    );
  },

  async set2FASetting({ dispatch, commit }, payload) {
    await userService.set2FASetting(payload).then(
      () => {
        commit("statusOk");
        dispatch(
          "snackbar/success",
          `Two Factor Authentication setting updated`,
          { root: true }
        );
        dispatch("reloadAccount");
      },
      (error) => {
        commit("statusError");
        dispatch("snackbar/error", error, {
          root: true,
        });
      }
    );
  },

  async request2FAVerification({ commit, dispatch }, payload) {
    await userService.request2FAVerification(payload).then(
      () => {
        commit("statusOk");
        dispatch(
          "snackbar/success",
          `Verification ${payload?.method == 'email' ? 'email' : 'text'} sent`,
          {
            root: true,
          }
        );
      },
      (error) => {
        commit("statusError");
        dispatch("snackbar/error", error, {
          root: true,
        });
      }
    );
  },

  async verify2FA({ dispatch, commit }, payload) {
    await userService.verify2FA(payload).then(
      () => {
        commit("statusOk");
        dispatch("snackbar/success", `Two Factor Authentication confirmed`, {
          root: true,
        });
      },
      (error) => {
        commit("statusError");
        dispatch("snackbar/error", error, {
          root: true,
        });
      }
    );
  },

  async reloadAccount({ commit, dispatch, state }) {
    await userService.reloadAccount().then(
      () => {
        let newUserData = JSON.parse(localStorage.getItem("user"));
        let userData = {
          ...state.data,
          ...newUserData,
        };
        commit("setData", userData);
        // dispatch("snackbar/success", `Account reloaded`, { root: true });
      },
      (error) => {
        commit("statusError");
        dispatch("snackbar/error", error, {
          root: true,
        });
      }
    );
  },

  updateSubscriptionStatus({ commit }, newStatus) {
    commit("setSubscriptionStatus", newStatus);
  },

  clearStatus({ commit }) {
    commit("statusOk");
  }
};

const mutations = {
  setAccount(state, data) {
    state.account = data;
  },

  setData(state, data) {
    state.data = data;
  },

  setSubscriptionStatus(state, newStatus) {
    let { data } = state;
    // console.log(state, newStatus);
    data.subscriptionStatus = newStatus;
    state.data = data;
    localStorage.setItem("user", JSON.stringify(data));
  },

  setTaxRate(state, data) {
    state.taxRate = data;
  },

  statusOk(state) {
    state.status = { error: false };
    state.statusText = "status-ok";
  },

  statusError(state, message) {
    state.status = { error: true, message: message };
    state.statusText = message;
  },

  createRequest(state) {
    state.status = { creating: true };
    state.statusText = "create-process";
  },

  createSuccess(state) {
    state.status = { created: true };
    state.statusText = "create-ok";
  },

  createFailure(state, message) {
    state.status = { error: true, message: message.message };
    state.statusText = "create-error";
  },

  loginRequest(state, user) {
    state.status = { loggingIn: true };
    state.statusText = "login-process";
    state.data = user;
  },

  loginSuccess(state, user) {
    state.status = { loggedIn: true };
    state.statusText = "login-ok";
    state.data = user;
  },

  loginFailure(state, message) {
    state.status = {
      error: true,
      message: message,
    };
    state.statusText = "login-error";
    state.data = null;
  },

  logout(state) {
    state.status = {};
    state.statusText = "logout-ok";
    state.user = null;
  },

  registerRequest(state) {
    state.status = { registering: true };
    state.statusText = "register-process";
  },
  registerSuccess(state) {
    state.status = {};
    state.statusText = "register-ok";
  },

  registerFailure(state, response) {
    state.statusText = "register-error";
    state.errors = UTIL.gv(response, "errors", []);
    console.log(state.errors);
    state.status = {
      error: true,
      message: "sss",
    };
  },
};

const getters = {
  user(state) {
    return state.data.user;
  },

  permissions(state) {
    let perms = state.data.permissions.permissions;
    let arr = {};
    for (let p1 in perms) {
      arr[perms[p1].featureName.toLowerCase()] = {};
      for (let p2 in perms[p1].featurePermissions) {
        arr[perms[p1].featureName.toLowerCase()][
          perms[p1].featurePermissions[p2].actionName.toLowerCase()
        ] = perms[p1].featurePermissions[p2].access;
      }
    }
    return arr;
  },

  hasAccess: (state, getters) => (key) => {
    state;
    return UTIL.gv(getters.permissions, key, false);
  },

  // getTaxRate: (state) => (stateCode) => {
  //   let found = state.states.find((data) => data.code === stateCode);
  //   if (found) {
  //     return found;
  //   } else {
  //     return state.states[0];
  //   }
  // },

  getTaxRate(state) {
    return state.taxRate;
    // let currentState = state.data.account.state;
    // let currentState = "CT";
    // let found = state.states.find(

    //   (data) => data.code === currentState

    // );
    // if (found) {
    //   return found.rate;
    // } else {
    //   return state.states[0].rate;
    // }
  },
};

export const account = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
