// import { invoiceService } from "src/services/mock";
import { invoiceService } from "src/services/api";
// import _ from "lodash";

const state = {
  status: {
    error: false
  },
  errors: [],
  statusText: "",

  single: {},
  singleGuid: {},
  singleAttachments: [],

  data: [],
  metaData: {},
  totalPaid: 0,
  totalDue: 0,
  totalOverdue: 0,

  summary: {
    totalBalance: 0,
    totalInvoice: 0,
    totalPaid: 0,
  },

  dataFilter: {
    clientId: "",
    status: "",
  },

  dataParam: {
    keyword: "",
    page: "",
    limit: 50,
  },
  nextNumber: "",

  tableFilter: {
    SearchTerm: null,
    clientId: null,
    dateStart: null,
    dateEnd: null,
    status: null,
  },
  loadingTable: false,
};

const defaultFilter = {
  SearchTerm: null,
  clientId: null,
  dateStart: null,
  dateEnd: null,
  status: null,
};

const actions = {
  async applyTableFilter({ commit, dispatch }, params) {
    commit("setTableFilter", {
      ...defaultFilter,
      ...params,
    });
    dispatch("getAll", params);
  },

  async clearTableFilter({ commit }) {
    commit("setTableFilter", {
      ...defaultFilter,
    });
  },

  async clearSingleAttachments({ commit }) {
    commit("setSingleAttachments", []);
  },

  async clearFilter({ commit }) {
    commit("clearFilterField", []);
    commit("setFilter", {
      keyword: "",
      page: "",
      limit: 50,
    });
  },

  async addFilterField({ commit }, params) {
    commit("setFilterField", params);
  },

  async search({ commit }, params = { keyword: "", page: "", limit: 50 }) {
    commit("setFilter", params);
  },

  async getStatementAll({ commit, dispatch }, queryParam = {}) {
    await invoiceService.getStatementAll(queryParam).then(
      (response) => {
        commit("setData", response.data);
      },
      (error) => {
        dispatch("alert/error", error, { root: true });
      }
    );
  },

  async getStatementSummary({ commit, dispatch }, queryParam = {}) {
    await invoiceService.getStatementSummary(queryParam).then(
      (response) => {
        commit("setSummary", response.data);
      },
      (error) => {
        dispatch("alert/error", error, { root: true });
      }
    );
  },

  async getAll({ commit, dispatch }, queryParam = {}) {
    commit("setLoadingTable", true);
    await invoiceService.getAll(queryParam).then(
      (response) => {
        commit("setData", response.data);
        commit("setMetaData", response.metaData);
        commit("setTotalPaid", response.totalPaid);
        commit("setTotalDue", response.totalDue);
        commit("setTotalOverdue", response.totalOverdue);
        commit("setLoadingTable", false);
      },
      (error) => {
        dispatch("alert/error", error, { root: true });
        commit("setLoadingTable", false);
      }
    );
  },

  async getNextNumber({ commit, dispatch }) {
    await invoiceService.getNextNumber().then(
      (response) => {
        commit("setNextNumber", response.data);
      },
      (error) => {
        dispatch("alert/error", error, { root: true });
      }
    );
  },

  async create({ commit, dispatch, state }, payload) {
    await invoiceService.create(payload).then(
      (response) => {
        commit("createSuccess", response);
        dispatch("getAll", state.tableFilter);
        dispatch("snackbar/success", `Add new invoice successful`, {
          root: true,
        });
      },
      (responseError) => {
        commit("createFailure", responseError);
        dispatch("alert/error", responseError, { root: true });
      }
    );
  },

  async pay({ commit, dispatch }, { payload, id }) {
    await invoiceService.pay(payload, id).then(
      (response) => {
        commit("createSuccess", response);
        dispatch("snackbar/success", `Pay invoice successful`, {
          root: true,
        });
      },
      (responseError) => {
        commit("createFailure", responseError);
        dispatch("alert/error", responseError, { root: true });
      }
    );
  },

  async clone({ commit, dispatch, state }, payload) {
    await invoiceService.create(payload).then(
      (response) => {
        commit("createSuccess", response);
        dispatch("getAll", state.tableFilter);
        dispatch("snackbar/success", `New invoice cloned successfully`, {
          root: true,
        });
      },
      (responseError) => {
        commit("createFailure", responseError);
        dispatch("alert/error", responseError, { root: true });
      }
    );
  },

  async createFromQuote({ commit, dispatch }, payload) {
    await invoiceService.createFromQuote(payload).then(
      (response) => {
        commit("createSuccess", response);
        dispatch("snackbar/success", `Quote converted to invoice successful`, {
          root: true,
        });
      },
      (responseError) => {
        commit("createFailure", responseError);
        dispatch("alert/error", responseError, { root: true });
      }
    );
  },

  async sendEmail({ commit, dispatch }, payload) {
    await invoiceService.sendEmail(payload).then(
      () => {
        commit("statusOk");
        dispatch("snackbar/success", `Send email invoice successful`, {
          root: true,
        });
      },
      (responseError) => {
        commit("createFailure", responseError);
        dispatch("alert/error", responseError, { root: true });
      }
    );
  },

  async logPayment({ commit, dispatch }, payload) {
    await invoiceService.logPayment(payload).then(
      () => {
        commit("statusOk");
        dispatch(
          "snackbar/success",
          `Payment invoice #${payload.id} successful`,
          {
            root: true,
          }
        );
      },
      (responseError) => {
        commit("createFailure", responseError);
        dispatch("alert/error", responseError, { root: true });
      }
    );
  },

  async update({ commit, dispatch, state }, payload) {
    await invoiceService.update(payload).then(
      (response) => {
        commit("updateSuccess", response);
        dispatch("getAll", state.tableFilter);
        dispatch(
          "snackbar/success",
          `Update invoice "${response.data.id}" successful`,
          { root: true }
        );
      },
      (responseError) => {
        commit("createFailure", responseError);
        dispatch("alert/error", responseError, { root: true });
      }
    );
  },

  async delete({ commit, dispatch }, payload) {
    await invoiceService.delete(payload).then(
      (response) => {
        commit("deleteSuccess", response);
        commit("deleteData", response.data);
        dispatch(
          "snackbar/success",
          `Delete invoice "${payload.id}" successful`,
          { root: true }
        );
      },
      (responseError) => {
        commit("createFailure", responseError);
        dispatch("alert/error", responseError, { root: true });
      }
    );
  },

  async void({ commit, dispatch }, payload) {
    await invoiceService.void(payload).then(
      (response) => {
        commit("voidSuccess", response);
        dispatch(
          "snackbar/success",
          `Void invoice "${payload.id}" successful`,
          { root: true }
        );
      },
      (responseError) => {
        commit("createFailure", responseError);
        dispatch("alert/error", responseError, { root: true });
      }
    );
  },

  async getById({ commit, dispatch }, id) {
    await invoiceService.getById(id).then(
      (response) => {
        commit("setSingle", response.data);
      },
      (responseError) => {
        commit("createFailure", responseError);
        dispatch("alert/error", responseError, { root: true });
      }
    );
  },

  async getByGuid({ commit, dispatch }, id) {
    await invoiceService.getByGuid(id).then(
      (response) => {
        commit("setSingleGuid", response.data);
      },
      (responseError) => {
        commit("createFailure", responseError);
        dispatch("alert/error", responseError, { root: true });
      }
    );
  },

  async getAttachments({ commit }, id) {
    commit("setLoadingTable", true);
    await invoiceService.getByIdFiles(id).then(
      (response) => {
        let { data } = response;
        commit("setSingleAttachments", data);
        commit("setLoadingTable", false);
      },
      (responseError) => {
        commit("createFailure", responseError);
        commit("setLoadingTable", false);
      }
    );
  },

  async addAttachments({ commit, dispatch }, payload) {
    commit("setLoadingAction", true);
    await invoiceService.addAttachments(payload).then(
      (response) => {
        commit("setLoadingAction", false);
        let formattedResponse = {
          data: response,
          message: "Update successfull",
        };
        commit("updateSuccess", formattedResponse);
        commit("updateData", formattedResponse.data);
        dispatch(
          "snackbar/success",
          `Associating files to invoice successful`,
          {
            root: true,
          }
        );
      },
      (responseError) => {
        commit("createFailure", responseError);
        dispatch("alert/error", responseError, { root: true });
        commit("setLoadingAction", false);
      }
    );
  },

  async deleteAttachments({ commit, dispatch }, payload) {
    commit("setLoadingAction", true);
    await invoiceService.deleteAttachments(payload).then(
      () => {
        commit("setLoadingAction", false);
        dispatch(
          "snackbar/success",
          `Delete associated files of invoice successful`,
          {
            root: true,
          }
        );
      },
      (responseError) => {
        commit("createFailure", responseError);
        dispatch("alert/error", responseError, { root: true });
        commit("setLoadingAction", true);
      }
    );
  },

  clearData({ commit }) {
    commit("setData", []);
  },
};

const mutations = {
  setLoadingAction(state, data) {
    state.loadingAction = data;
  },

  setSingleAttachments(state, data) {
    let _data = data.map((item) => {
      return {
        ...item,
        isFolder: false,
        icon: "mdi-file",
        parentId: item.parentId ? item.parentId : "",
      };
    });
    state.singleAttachments = _data;
  },

  setLoadingTable(state, data) {
    state.loadingTable = data;
  },

  setTableFilter(state, data) {
    state.tableFilter = data;
  },

  setMetaData(state, data) {
    state.metaData = data;
  },

  setSingle(state, data) {
    state.single = data;
  },

  setSingleGuid(state, data) {
    state.singleGuid = data;
  },

  setSummary(state, data) {
    state.summary = data;
  },

  clearFilterField(state) {
    state.dataFilter = [];
  },

  setFilterField(state, param) {
    state.dataFilter = param;
  },

  setFilter(state, param) {
    state.dataParam = param;
  },

  setNextNumber(state, data) {
    state.nextNumber = data;
  },

  setData(state, data) {
    state.data = data;
  },

  appendData(state, item) {
    state.data.push(item);
  },

  updateData(state, item) {
    const index = state.data.findIndex((data) => data.id === item.id);
    if (index !== -1) state.data.splice(index, 1, item);
  },

  deleteData(state, item) {
    state.data.splice(state.data.indexOf(item.id), 1);
  },

  setTotalPaid(state, totalPaid) {
    state.totalPaid = totalPaid;
  },

  setTotalDue(state, totalDue) {
    state.totalDue = totalDue;
  },

  setTotalOverdue(state, totalOverdue) {
    state.totalOverdue = totalOverdue;
  },

  statusOk(state) {
    state.statusText = "ok";
    state.status = { error: false, message: "ok" };
  },

  createSuccess(state, response) {
    state.statusText = "create-ok";
    state.status = { error: false, message: response.message };
  },

  deleteSuccess(state, response) {
    state.statusText = "delete-ok";
    state.status = { error: false, message: response.message };
  },

  voidSuccess(state, response) {
    state.statusText = "void-ok";
    state.status = { error: false, message: response.message };
  },

  updateSuccess(state, response) {
    state.statusText = "update-ok";
    state.status = { error: false, message: response.message };
  },

  createFailure(state, response) {
    state.statusText = "create-error";
    state.status = { error: true, message: response.message };
  },
};

const getters = {
  getTableFilter(state) {
    return state.tableFilter;
  },
  getAll(state) {
    let data = state.data;
    return data;
  },
  getMetaData(state) {
    let data = state.metaData;
    return data;
  },
};

export const invoice = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
