export default [
  {
    params: {
      path: "/dashboard",
      name: "Dashboard",
    },
    defaultComponentPath: "Pages/Calendar",
    navs: true,
  },
  {
    params: {
      path: "/profile",
      name: "ProfileView",
    },
    defaultComponentPath: "Pages/Profile/Profile",
    navs: true,
  },
  {
    params: {
      path: "/account",
      name: "Account",
    },
    defaultComponentPath: "Pages/Account",
    navs: true,
  },
  {
    params: {
      path: "/account/user-profile",
      name: "User Profile",
    },
    defaultComponentPath: "Pages/Account",
    navs: true,
  },
  {
    params: {
      path: "/account/company",
      name: "Company",
    },
    defaultComponentPath: "Pages/Account",
    navs: true,
  },
  {
    params: {
      path: "/account/billing",
      name: "Billing",
    },
    defaultComponentPath: "Pages/Account",
    navs: true,
  },
  {
    params: {
      path: "/account/invoice-emails",
      name: "Invoice Emails",
    },
    defaultComponentPath: "Pages/Account",
    navs: true,
  },
  {
    params: {
      path: "/test-page",
      name: "TestPage",
    },
    defaultComponentPath: "Pages/TestPage",
    navs: true,
  },

  {
    params: {
      path: "/clients/create",
      name: "ClientCreate",
    },
    defaultComponentPath: "Pages/Client/ClientAdd",
    navs: true,
  },

  {
    params: {
      path: "/clients/:id/edit",
      name: "ClientEdit",
    },
    defaultComponentPath: "Pages/Client/ClientEdit",
    navs: true,
  },
  {
    params: {
      path: "/clients/:id",
      name: "ClientDetail",
    },
    defaultComponentPath: "Pages/Client/ClientDetail",
    navs: true,
  },
  {
    params: {
      path: "/clients",
      name: "Clients",
    },
    defaultComponentPath: "Pages/Clients",
    navs: true,
  },

  {
    params: {
      path: "/clients/:clientId/invoice/create",
      name: "InvoicesClientCreate",
    },
    defaultComponentPath: "Pages/Invoice/Form",
    navs: true,
  },
  {
    params: {
      path: "/vendors/create",
      name: "VendorCreate",
    },
    defaultComponentPath: "Pages/Vendor/VendorAdd",
    navs: true,
  },
  {
    params: {
      path: "/vendors/:id/edit",
      name: "VendorEdit",
    },
    defaultComponentPath: "Pages/Vendor/VendorEdit",
    navs: true,
  },
  {
    params: {
      path: "/vendors/:id",
      name: "VendorDetail",
    },
    defaultComponentPath: "Pages/Vendor/VendorDetail",
    navs: true,
  },
  {
    params: {
      path: "/vendors",
      name: "Vendors",
    },
    defaultComponentPath: "Pages/Vendors",
    navs: true,
  },

  {
    params: {
      path: "/vendors/:vendorId/expense/create",
      name: "ExpensesVendorCreate",
    },
    defaultComponentPath: "Pages/Expense/Form",
    navs: true,
  },

  {
    params: {
      path: "/categories",
      name: "Categories",
    },
    defaultComponentPath: "Pages/Categories",
    navs: true,
  },

  {
    params: {
      path: "/task-list",
      name: "TaskList",
      showTitle: true,
    },
    defaultComponentPath: "Pages/TaskList",
    navs: true,
  },

  {
    params: {
      path: "/password-vault",
      name: "Vault",
      showTitle: true,
    },
    defaultComponentPath: "Pages/Vault",
    navs: true,
  },

  {
    params: {
      path: "/vault-folder",
      name: "VaultFolder",
      showTitle: true,
    },
    defaultComponentPath: "Pages/VaultFolder",
    navs: true,
  },

  {
    params: {
      path: "/task-list/client/:clientId",
      name: "TaskListClient",
    },
    defaultComponentPath: "Pages/TaskList",
    navs: true,
  },
  {
    params: {
      path: "/time-clock",
      name: "TimeClock",
    },
    defaultComponentPath: "Pages/TimeClock",
    navs: true,
  },

  {
    params: {
      path: "/quotes/create",
      name: "QuotesCreate",
    },
    defaultComponentPath: "Pages/Quotes/Form",
    navs: true,
  },

  {
    params: {
      path: "/quotes/:quoteId/edit",
      name: "QuoteEdit",
    },
    defaultComponentPath: "Pages/Quotes/Form",
    navs: true,
  },

  {
    params: {
      path: "/quotes/:quoteId/clone",
      name: "QuoteClone",
      props: {
        default: { clone: true }
      }
    },
    defaultComponentPath: "Pages/Quotes/Form",
    navs: true,
  },

  {
    params: {
      path: "/quotes/:quoteId/invoice",
      name: "QuoteFormInvoice",
    },
    defaultComponentPath: "Pages/Quotes/FormInvoice",
    navs: true,
  },

  {
    params: {
      path: "/quotes/client/:clientId",
      name: "QuotesClient",
    },
    defaultComponentPath: "Pages/Quotes",
    navs: true,
  },
  {
    params: {
      path: "/quotes",
      name: "Quotes",
    },
    defaultComponentPath: "Pages/Quotes",
    navs: true,
  },

  {
    params: {
      path: "/invoices",
      name: "Invoices",
    },
    defaultComponentPath: "Pages/Invoices",
    navs: true,
  },
  {
    params: {
      path: "/invoices/client/:clientId",
      name: "InvoicesClient",
    },
    defaultComponentPath: "Pages/Invoices",
    navs: true,
  },

  {
    params: {
      path: "/invoices/:invoiceId/edit",
      name: "InvoiceEdit",
    },
    defaultComponentPath: "Pages/Invoice/Form",
    navs: true,
  },

  {
    params: {
      path: "/invoices/:invoiceId/clone",
      name: "InvoiceClone",
      props: {
        default: { clone: true }
      }
    },
    defaultComponentPath: "Pages/Invoice/Form",
    navs: true,
  },

  {
    params: {
      path: "/invoices/create",
      name: "New Invoice",
    },
    defaultComponentPath: "Pages/Invoice/Form",
    navs: true,
  },
  {
    params: {
      path: "/gginvoices",
      name: "GGInvoices",
    },
    defaultComponentPath: "Pages/GGInvoices",
    navs: true,
  },

  {
    params: {
      path: "/gginvoices/:invoiceId/edit",
      name: "Edit Invoice",
    },
    defaultComponentPath: "Pages/Invoice/GGForm",
    navs: true,
  },

  {
    params: {
      path: "/gginvoices/create",
      name: "Create Invoice",
    },
    defaultComponentPath: "Pages/Invoice/GGForm",
    navs: true,
  },

  {
    params: {
      path: "/items",
      name: "Item List",
    },
    defaultComponentPath: "Pages/Item",
    navs: true,
  },
  {
    params: {
      path: "/user/users",
      name: "Users",
    },
    defaultComponentPath: "Pages/User/Users",
    navs: true,
  },
  {
    params: {
      path: "/user-groups",
      name: "UserGroup",
    },
    defaultComponentPath: "Pages/User/UserGroupNew",
    navs: true,
  },
  {
    params: {
      path: "/reports",
      name: "Reports",
    },
    defaultComponentPath: "Pages/Reports",
    navs: true,
  },
  // {
  //   params: {
  //     path: "/user/permissions",
  //     name: "pages/user/Permissions",
  //   },
  //   defaultComponentPath: "Pages/User/Permissions",
  //   navs: true,
  // },
  // {
  //   params: {
  //     path: "/user/roles",
  //     name: "pages/user/Roles",
  //   },
  //   defaultComponentPath: "Pages/User/Roles",
  //   navs: true,
  // },

  {
    params: {
      path: "/payment/:id",
      name: "public/invoices",
      meta: { layout: "auth" },
    },
    defaultComponentPath: "Pages/Public/Invoices",
    navs: false,
  },

  {
    params: {
      path: "/login",
      name: "pages/authentication/LoginPage",
      meta: { layout: "auth" },
    },
    defaultComponentPath: "Pages/Authentication/Login/Login",
  },
  {
    params: {
      path: "/forgot-password",
      name: "pages/authentication/ForgotPasswordPage",
      meta: { layout: "auth" },
    },
    defaultComponentPath: "Pages/Authentication/ForgotPassword/ForgotPassword",
  },
  {
    params: {
      path: "/signup",
      name: "pages/authentication/SignupPage",
      meta: { layout: "auth" },
    },
    defaultComponentPath: "Pages/Authentication/Signup/Signup",
  },
  {
    params: {
      path: "/activate",
      name: "InactiveAccountLandingPage",
      meta: { layout: "auth" },
    },
    defaultComponentPath: "Pages/InactiveAccountLandingPage",
  },
  {
    params: {
      path: "/verify",
      name: "UnverifiedEmailLandingPage",
      meta: { layout: "auth" },
    },
    defaultComponentPath: "Pages/UnverifiedEmailLandingPage",
  },
  {
    params: {
      path: "/lock-screen",
      name: "pages/authentication/LockScreenPage",
      meta: { layout: "auth" },
    },
    defaultComponentPath: "Pages/Authentication/LockScreen/LockScreen",
  },
  {
    params: {
      path: "/coming-soon",
      name: "ComingSoon",
      meta: { layout: "full" },
    },
    defaultComponentPath: "Pages/ComingSoon",
  },
  {
    params: {
      path: "/under-maintenance",
      name: "MaintenancePage",
      meta: { layout: "full" },
    },
    defaultComponentPath: "Pages/Maintenance",
  },
  {
    params: {
      path: "/404",
      name: "pages/error/Error404",
      meta: { layout: "full" },
    },
    defaultComponentPath: "Pages/Errors/Error404",
  },
  {
    params: {
      path: "/500",
      name: "pages/error/Error500",
      meta: { layout: "full" },
    },
    defaultComponentPath: "Pages/Errors/Error500",
  },
  {
    params: {
      path: "/pay",
      name: "Pay",
    },
    defaultComponentPath: "Pages/Pay",
    navs: true,
  },
  {
    params: {
      path: "/pay/:id",
      name: "Pay Invoice",
    },
    defaultComponentPath: "Pages/Pay",
    navs: true,
  },
  {
    params: {
      path: "/payment-history",
      name: "PaymentHistory",
    },
    defaultComponentPath: "Pages/PaymentHistory",
    navs: true,
  },
  {
    params: {
      path: "/expenses",
      name: "Expenses",
    },
    defaultComponentPath: "Pages/Expenses",
    navs: true,
  },
  {
    params: {
      path: "/storage",
      name: "Storage",
      showTitle: true,
    },
    defaultComponentPath: "Pages/Storage",
    navs: true,
  },
  {
    params: {
      path: "/audit-log",
      name: "AuditLog",
    },
    defaultComponentPath: "Pages/AuditLog",
    navs: true,
  },
  {
    params: {
      path: "/manage-accounts",
      name: "ManageAccounts",
    },
    defaultComponentPath: "Pages/ManageAccounts",
    navs: true,
  }
  //  {
  //    params: {
  //      path: "*",
  //      name: "PageNotFound",
  //      meta: { layout: "full" },
  //    },
  //    defaultComponentPath: "Pages/Errors/Error404",
  //  },
];
