import { customRequest, handleResponse, accountId } from "src/helpers";

const config = {
  apiUrl: process.env.VUE_APP_BACKEND_URL,
};

export const companyService = {
  getById,
  update,
  getAccount,
  updatePayment,
  downloadLogo,
  uploadLogo,
  accountId
};

function getById(id) {
  const requestOptions = {
    ...customRequest("GET"),
  };
  return fetch(`${config.apiUrl}/Accounts/${id}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return {
        data: response,
      };
    });
}

function getAccount() {
  const requestOptions = {
    ...customRequest("GET"),
  };
  return fetch(`${config.apiUrl}/Accounts`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return {
        data: response,
      };
    });
}

function update(payload) {
  const requestOptions = {
    ...customRequest("PUT", payload),
  };
  return fetch(`${config.apiUrl}/Accounts/${payload.id}`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return {
        data: response,
      };
    });
}

function downloadLogo(id) {
  const requestOptions = {
    ...customRequest("GET"),
  };

  return fetch(`${config.apiUrl}/BlobFiles/${id}/download?isLogo=true`, requestOptions)
}

function uploadLogo(payload) {
  const user = JSON.parse(localStorage.getItem("user"));

  const formData  = new FormData();
  formData.append("File", payload.file, payload.filename)

  const url = `${config.apiUrl}/BlobFiles/upload/logo`;
  const requestOptions = {
    method: "POST",
    headers: {
      "Authorization": "Bearer " + user.token,
      Charset: "utf-8"
    },
    body: formData
  };
  return fetch(url, requestOptions)
       .then(r => r.json()) 
       .then(data => {
         return data;
       })
}

function updatePayment(payload) {
  const requestOptions = {
    ...customRequest("POST"),
  };
  return fetch(
    `${config.apiUrl}/Accounts/UpdatePaymentMethod?paymentNonce=${payload.paymentNonce}`,
    requestOptions
  )
    .then(handleResponse)
    .then((response) => {
      return {
        data: response,
      };
    });
}

